var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"applyActivity"},[_c('div',{staticClass:"tab"},_vm._l((_vm.tabList),function(item,index){return _c('div',{key:index,class:['item', { active: _vm.requestData.activityCategory == item.key }],on:{"click":function($event){return _vm.selectMem(item, index)}}},[_vm._v(" "+_vm._s(item.label)+" ")])}),0),_c('v-list',{ref:"list",attrs:{"finished":_vm.finished,"finishedText":_vm.finishedText,"commonOperaParams":_vm.commonOperaParams},on:{"onLoad":_vm.onLoad}},_vm._l((_vm.listData),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"activityItem",on:{"click":function($event){return _vm.toDetail(item)}}},[_c('div',{staticClass:"activityItem-t"},[_c('img',{attrs:{"src":item.activityPicture,"alt":""}})]),_c('div',{staticClass:"activityItem-c"},[_c('div',{staticClass:"title"},[(item.isApplied == 1)?_c('div',{staticClass:"title-l"},[_c('img',{attrs:{"src":require("./img/signUp.png"),"alt":""}}),_c('span',[_vm._v("已报名")])]):_vm._e(),_c('div',{staticClass:"title-r"},[_vm._v(_vm._s(item.activityName))])]),_c('div',{staticClass:"address"},[_vm._v(_vm._s(item.activityAddress))]),_c('p',{staticClass:"p-apply"},[_vm._v("报名开始时间："+_vm._s(item.applyStartDate))]),_c('p',{staticClass:"p-activity"},[_vm._v("活动开始时间："+_vm._s(item.startDate))])]),_c('div',{staticClass:"activityItem-b"},[_c('div',{staticClass:"operate"},[_c('div',{staticClass:"operate-l"},[_c('div',{staticClass:"people"},[_c('div',{staticClass:"people-l"},[_c('img',{attrs:{"src":require("./img/newPeople.png"),"alt":""}})]),_c('div',{staticClass:"people-r"},[_vm._v(_vm._s(item.applyUserCount || 0))])]),_c('div',{staticClass:"comment"},[_c('div',{staticClass:"comment-l"},[_c('img',{attrs:{"src":require("./img/comment.png"),"alt":""}})]),_c('div',{staticClass:"comment-r"},[_vm._v(_vm._s(item.commentCount || 0))])])]),_c('div',{staticClass:"operate-r"},[_c('div',{class:[
                  'btn',
                  {
                    applying: item.activityStatus == 2,
                    going: item.activityStatus == 4,
                    back: item.hasFeedback && item.hasFeedback == 1,
                  },
                ]},[_vm._v(" "+_vm._s(_vm.getOption(item.activityStatus, item.hasFeedback, item))+" ")])])])])])])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }