<template>
  <div class="applyActivity">
    <div class="tab">
      <div
        v-for="(item, index) in tabList"
        :key="index"
        :class="['item', { active: requestData.activityCategory == item.key }]"
        @click="selectMem(item, index)"
      >
        {{ item.label }}
      </div>
    </div>
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      ref="list"
      :finishedText="finishedText"
      :commonOperaParams="commonOperaParams"
    >
      <div v-for="(item, index) in listData" :key="index">
        <div class="activityItem" @click="toDetail(item)">
          <div class="activityItem-t">
            <img :src="item.activityPicture" alt="" />
          </div>
          <div class="activityItem-c">
            <div class="title">
              <div class="title-l" v-if="item.isApplied == 1">
                <img src="./img/signUp.png" alt="" /><span>已报名</span>
              </div>
              <div class="title-r">{{ item.activityName }}</div>
            </div>
            <div class="address">{{ item.activityAddress }}</div>
            <p class="p-apply">报名开始时间：{{ item.applyStartDate }}</p>
            <p class="p-activity">活动开始时间：{{ item.startDate }}</p>
          </div>
          <div class="activityItem-b">
            <div class="operate">
              <div class="operate-l">
                <div class="people">
                  <div class="people-l">
                    <img src="./img/newPeople.png" alt="" />
                  </div>
                  <div class="people-r">{{ item.applyUserCount || 0 }}</div>
                </div>
                <div class="comment">
                  <div class="comment-l">
                    <img src="./img/comment.png" alt="" />
                  </div>
                  <div class="comment-r">{{ item.commentCount || 0 }}</div>
                </div>
              </div>
              <div class="operate-r">
                <div
                  :class="[
                    'btn',
                    {
                      applying: item.activityStatus == 2,
                      going: item.activityStatus == 4,
                      back: item.hasFeedback && item.hasFeedback == 1,
                    },
                  ]"
                >
                  {{ getOption(item.activityStatus, item.hasFeedback, item) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-list>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { getActivityListUrl } from "./api.js";
import { getOption, handleImg } from "@/utils/utils.js";

import { activityStatus } from "./map.js";
import { gloabalCount } from "@/utils/common";
export default {
  name: "applyActivity",
  data() {
    return {
      finishedText: "没有更多了",
      activityStatus,
      finished: false,
      commonOperaParams: {
        showMy: false,
        isCommonOpera: false,
      },
      requestData: {
        curPage: 1,
        pageSize: 10,
        activityCategory: 1,
      },
      tabList: [
        { label: "热门活动", key: "1" },
        { label: "幸福学堂", key: "19" },
      ],
      listData: [],
    };
  },
  created() {
    if (this.$route.query.activityCategory) {
      this.requestData.activityCategory = this.$route.query.activityCategory;
    }
  },
  mounted() {
    gloabalCount("", 2, 1);
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
  },
  methods: {
    selectMem(item, index) {
      this.requestData.activityCategory = item.key;
      this.finished = false;
      this.finishedText = "没有更多了";
      this.$refs.list._data.loading = true;
      this.requestData.curPage = 1;
      this.listData = [];
      this.getActivityList();
    },
    getOption(status, hasFeedback, item) {
      if (hasFeedback && hasFeedback == 1) {
        return "活动回顾";
      }
      if (item.applyUserCount >= item.limitCount && item.limitCount != 0) {
        return "报名已满";
      }
      if (getOption(status, activityStatus, "key")["value"]) {
        return getOption(status, activityStatus, "key")["value"];
      } else {
        return "";
      }
    },
    getActivityList() {
      let params = {
        sendPointFlag: 1, // 传1 表示调该接口时后端会自行调获取组合任务积分的接口 不传此参数则不掉
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        userId: this.userId,
        activityCategory: this.requestData.activityCategory,
      };
      this.$axios
        .get(`${getActivityListUrl}`, { params: params })
        .then((res) => {
          console.log(res, 333);
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              if (item.activityPicture) {
                item.activityPicture = handleImg(
                  400,
                  300,
                  item.activityPicture
                );
              }
              this.listData.push(item);
            });
          } else {
          }
        });
    },
    onLoad() {
      this.getActivityList();
    },
    toDetail(item) {
      let params = {
        activityId: item.activityId,
      };
      wx.miniProgram.navigateTo({
        url: `/xubPages/activityDetail/index?params=${JSON.stringify(params)}`,
      });
      // this.$router.push({
      //   name: "activityDetail",
      //   query: { activityId: item.activityId },
      // });
    },
  },
};
</script>
<style lang="less" scoped>
.applyActivity {
  padding: 120px 32px;
  box-sizing: border-box;
}
.tab {
  display: flex;
  justify-content: space-between;
  background: #fff;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 999;
  .item {
    flex: 1;
    text-align: center;
    padding: 28px 0;
    font-size: 28px;
    font-weight: 400;
    color: #323334;
    line-height: 40px;
  }
  .active {
    position: relative;
    font-weight: 700;
    &::after {
      content: "";
      position: absolute;
      width: 64px;
      height: 8px;
      background: #007eff;
      border-radius: 4px;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}
.activityItem {
  height: 604px;
  border-radius: 16px;
  background: #ffffff;
  box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
  margin-bottom: 32px;
  .activityItem-t {
    width: 100%;
    height: 280px;
    overflow: hidden;
    position: relative;
    img {
      border-radius: 16px 16px 0 0;
      width: 100%;
      //height: 100%;
    }
  }
  .activityItem-c {
    padding: 20px 24px 22px;
    box-sizing: border-box;
    .title {
      display: flex;
      align-items: center;
      .title-l {
        width: 102px;
        height: 32px;
        margin-right: 8px;
        position: relative;
        font-size: 0;
        img {
          width: 100%;
          height: 100%;
        }
        span {
          width: 100%;
          height: 100%;
          text-align: center;
          position: absolute;
          top: 0;
          left: 0;
          font-size: 20px;
          line-height: 32px;
          color: #fff;
          font-weight: bold;
        }
      }
      .title-r {
        width: 480px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 32px;
        font-weight: bold;
        color: #323334;
        line-height: 44px;
      }
    }
    .address {
      font-size: 24px;
      font-weight: 400;
      color: #97999b;
      line-height: 36px;
      margin: 8px 0 14px;
    }
    p {
      font-size: 24px;
      padding-left: 24px;
      font-weight: 400;
      color: #646668;
      line-height: 36px;
      margin-bottom: 6px;
    }
    .p-apply,
    .p-activity {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        background: #1ebb70;
        border-radius: 50%;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
    .p-activity {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        background: #3a69ed;
        border-radius: 50%;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
  .activityItem-b {
    padding: 0 24px;
    box-sizing: border-box;
  }
  .activityItem-b {
    .operate {
      border-top: 1px solid #ebebeb;
      display: flex;
      padding: 14px 0 0 0;
      box-sizing: border-box;
      justify-content: space-between;
      align-items: center;
      .operate-l,
      .operate-r {
        display: flex;
        align-items: center;
      }
      .people {
        width: 160px;
      }
      .people,
      .comment {
        display: flex;
        align-items: center;
      }
      .people-l,
      .comment-l {
        width: 40px;
        height: 40px;
        font-size: 0;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .people-r,
      .comment-r {
        color: #646668;
        padding-top: 2px;
        line-height: 1;
        font-size: 30px;
        font-weight: 400;
        color: #646668;
      }
      .btn {
        height: 64px;
        font-weight: bold;
        border-radius: 32px;
        width: 200px;
        background: #cbcccd;
        font-size: 28px;
        text-align: center;
        color: #fff;
        line-height: 64px;
      }
      .btn.applying {
        background: #fe6f16;
        //color: #999;
      }
      .btn.going {
        background: #ffffff;
        border: 2px solid #fe6f16;
        color: #fe6f16;
      }
      .btn.back {
        background: #ffffff;
        border: 2px solid #007eff;
        color: #007eff;
      }
    }
  }
}
</style>
